$font: "IBM Plex Sans", sans-serif;

$max-content-width: 1200px;

$color-pitch-black: #000000;
$color-black: #45494e;
$color-grey-very-dark: #343538;
$color-grey-dark: #65676f;
$color-grey: #a0a8ad;
$color-grey-medium: #f6f7fa;
$color-grey-light: #f5f7f9;
$color-grey-very-light: #edf0f3;
$color-white: #ffffff;

$color-input-label: #a6b0c3;
$color-nav-links: #9297a2;
$color-footer: #dce2eb;

$color-red: #ff0000;
$color-green: #008000;
$color-orange: #dc7f4c;
$color-marmalade-dark: #a45a31;
$color-marmalade: #fa7b35;
$color-marmalade-light: #fcae83;
$color-marmalade-lighter: #fdceb4;
$color-marmalade-lightest: #feefe6;
$color-orange-light: #fff1e9;

$color-status-red: #f5beb7;
$color-status-yellow: #f4f2b7;
$color-status-green: #b7f4ba;
$color-status-grey: #dce2eb;

$color-status-red-text: #710808;
$color-status-green-text: #006400;

$shadow-color-dark: rgba(36, 41, 48, 0.1);
$shadow-color-light: rgba(36, 41, 48, 0.05);

$transition: 0.2s all ease;
$transition-fast: 0.1s all ease;

$z-index-high: 99;
$z-index-medium: 50;
$z-index-low: 0;

$breakpoint-vertical-nav: 959px;
$breakpoint-vertical-search-results: 632px;
$breakpoint-vertical-active-submission-info: 990px;
$breakpoint-mobile-nav: 569px;
