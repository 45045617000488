* {
  box-sizing: border-box;
}

body,
html {
  margin: 10px;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: $color-grey-very-light;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  max-width: $max-content-width;
  margin: 0 auto;
}

.loading {
  position: relative;
  min-height: 0.75rem;
  min-width: 0.75rem;
  margin: 0 0.25rem;
  pointer-events: none;
}

.loading:after {
  content: "";
  position: absolute;
  left: calc(50% - 0.625rem);
  top: calc(50% - 0.625rem);
  border: 2px solid transparent;
  border-top: 2px solid white;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 0.5s linear infinite, fadeIn 0.25s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
